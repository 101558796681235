<template>
    <Button
        :variant="variant"
        @click="() => router.visit(route('register.with-price', {price: priceValue}), { method: 'get' })"
        :title="isPromo ? 'Special Offer!' : 'Start your free trial today'"
        class="scale-125 lg:scale-125 origin-center">

        <slot name="default">
            Start Your Free Trial
        </slot>
    </Button>
</template>
<script setup lang="ts">
import {router, usePage} from "@inertiajs/vue3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {mdiBullseyeArrow} from "@mdi/js";
import MdiIcon from "@/Components/MdiIcon.vue";
//import {route} from "ziggy-js";
import {onMounted, ref} from "vue";
import {Button} from "@/Components/ui/button";

const products = usePage().props.products
const priceValue = ref(products[1].value)
const isPromo = ref(false)

defineProps({
    variant: {
        type: String,
        default: 'primary'
    }
})

onMounted(() => {
    const url = window.location.href

    if (url.includes('founders')) {
        priceValue.value = products[1].promo_price
        isPromo.value = true
    }
})
</script>
